<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a @click.prevent="selectWorkspace" href="#" class="sidebar-brand">
        <img :src="'/img/'+(env('VUE_APP_COBRAND') || 'wappin')+'-logo-white.png'" height="30" />
      </a>
      <div
        class="sidebar-toggler active"
        @click="toggleSidebar"
        ref="sidebarToggler">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <ul class="nav">
        <SidebarCategory title="Main" />
        <SidebarItem
          v-for="(menuItem, i) in menuList"
          :key="i"
          :data="menuItem"
        />
        <SidebarCategory title="Label" />
        <SidebarItem
          v-for="(label, i) in labelList"
          :key="'label_' + i"
          :data="label"
        />
        <!-- <SidebarItem :data="{ to: '#1', title: 'Lorem', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#2', title: 'Ipsum', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#3', title: 'Dolor', icon: 'tag' }" />
        <SidebarItem :data="{ to: '#4', title: 'Sit Amet', icon: 'tag' }" /> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import { isEmpty } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import cookie from '../../library/cookie';
import customFeaturesApi from '../../api/customFeatures';
import labelsAPI from '../../api/labels';

const menu = (to, title, icon, child = [], el_icon = '', pic_only = false, division_admin_only = false) => ({
  to,
  title,
  icon,
  child,
  el_icon,
  pic_only,
  division_admin_only,
});

export default {
  name: 'Sidebar',
  metaInfo: {
    bodyAttrs: {
      class: ['sidebar-dark'],
    },
  },
  data() {
    return {
      /* eslint-disable no-unneeded-ternary */
      isFolded: false,
      menuList: [
        menu('/dashboard', 'Dashboard', 'activity'),
        menu('/conversations', 'Conversations', 'message-square'),
        menu('/notifications', 'Notifications', 'bell', [
          menu('/notifications', 'Create Notifications'),
          menu('/schedule', 'Schedules'),
        ], '', true),
        menu('/broadcast-templates', 'Broadcast Templates', '', [], 'custom-icon el-icon-notebook-2', true),
        menu('/contacts', 'Contacts', 'book', [
          menu('/contacts/list', 'List Contacts'),
          menu('/contacts/contact-groups', 'Group Contacts'),
        // ], '', true),
        ]),
        // menu('/reports', 'Reports', 'pie-chart'),
        menu('/reports', 'Reports', 'pie-chart', [
          menu('/reports/helpdesk', 'Helpdesk'),
          menu('/reports/session', 'Channels Session'),
          menu('/reports/mau', 'MAU Usage'),
          menu('/reports/broadcast', 'Broadcast'),
          menu('/reports/call', 'Call'),
          menu('/reports/download', 'Download'),
        ]),
        menu('/settings/users', 'Users', 'user', [], '', true),
        menu('/settings/divisions', 'Divisions', '', [], 'fa users', true, true),
        menu('/settings/agents', 'Agents', '', [], 'fa headset', true),
        menu('/settings/channels', 'Channels', 'cast', [], '', true),
        menu('/settings/groups', 'Groups', 'users', [], '', true),
        menu('/settings/labels', 'Labels', 'tag', [], '', true),
        menu('/settings/quick-replies', 'Quick Replies', '', [], 'custom-icon el-icon-chat-line-square', true),
        menu('/chatbot-templates', 'Chatbot Template', '', [], 'fa robot', true),
        menu('/settings/general', 'Settings', 'settings', '', [], true),
        menu('/integrations', 'Integrations', 'git-branch', '', [], true),
        menu('https://wappin.matamaya.id', 'Social Media Analytic', '', [], 'fa fa-icons', true),
        menu('#', 'Commerce', 'shopping-cart', [
          menu('/commerce/product', 'Product'),
          menu('/commerce/payments', 'Payments'),
          menu('/coming-soon#product-category', 'Product Category'),
          menu('/commerce/setting', 'Setting'),
          menu('/coming-soon#orders', 'Orders'),
        ], '', true),
        menu('/flows', 'Flows', 'layers', '', [], true),
        menu('#', 'Leads', 'server', [
          menu('/leads/board', 'Board'),
          menu('/leads/schedule', 'Schedule'),
          menu('/leads/completed', 'History'),
          menu('/leads/settings', 'Settings'),
        ]),
        // menu('/settings', 'Settings', 'settings', [
        // menu('/settings/teams', 'Teams'),
        // menu('/settings/labels', 'Labels'),
        // menu('/settings/canned-responses', 'Canned Responses'),
        // menu('/settings/integrations', 'Integrations'),
        // menu('/settings/account', 'Account Settings'),
        // ]),
        // menu('/teams', 'Teams', 'users', [
        //   menu('/teams/marketing', 'Marketing'),
        //   menu('/teams/customer-success', 'Customer Success'),
        //   menu('/teams/engineering', 'Engineering'),
        // ]),
      ],
      perfectScroll: [],
      labelList: [],
    };
  },
  mounted() {
    const is_agent = !isEmpty(this.userProfile.agents[0]);
    const { is_pic } = this.$store.state.auth.userProfile;
    const is_division_admin = this.userProfile.members ? this.userProfile.members.find((v) => v.workspaces_id.includes(this.activeWorkspace._id)) : {};
    if (!is_pic) {
      if (is_agent) {
        this.menuList = this.menuList.filter((v) => !v.pic_only);
      }
      if (is_division_admin && !is_division_admin.is_admin) {
        this.menuList = this.menuList.filter((v) => !v.division_admin_only);
      }
    }
    if (this.$store.state.auth.userProfile.company_id === '96y5cKiZbwH7myjS_ZEuA') { // digitalent
      this.menuList = this.menuList.filter((v) => !['/contacts'].includes(v.to));
    }
    if (this.$store.state.auth.userProfile.company_id === 'esz0i3fGsAUViMmTVp44X' || this.activeWorkspace._id === '650d279e44d15f5597e27a1c') { // digitalent
      this.menuList = this.menuList.filter((v) => !['/conversations'].includes(v.to));
    }
    this.setSidebar();
    if (document.querySelectorAll('.sidebar .sidebar-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.sidebar-body'));
    }
    if (document.querySelectorAll('.content-nav-wrapper').length) {
      this.perfectScroll.push(new PerfectScrollbar('.content-nav-wrapper'));
    }
    if (!this.isEnableCallFeature && !this.isEnableVoIPFeature) {
      const menuReport = this.menuList.find((v) => v.to === '/reports');
      const menuReportIndex = this.menuList.findIndex((v) => v.to === '/reports');
      const indexMenuCall = menuReport.child.findIndex((v) => v.to === '/reports/call');
      this.menuList[menuReportIndex].child[indexMenuCall] = undefined;
      this.menuList[menuReportIndex].child = this.menuList[menuReportIndex].child.filter((v) => v);
    }
    this.getLabelList();
    this.getCustomFeature();
  },
  computed: {
    isLargeScreen: () => window.document.body.clientWidth > 991,
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    isEnableCallFeature() {
      return this.activeWorkspace && this.activeWorkspace.products ? this.activeWorkspace.products[0].pricing.whatsapp.enable_call_feature : false;
    },
    isEnableVoIPFeature() {
      if (this.activeWorkspace && this.activeWorkspace.products) {
        return this.activeWorkspace.products[0].pricing.phone_call ? this.activeWorkspace.products[0].pricing.phone_call.enable_feature : false;
      }
      return false;
    },
  },
  watch: {
    isLargeScreen() {
      this.setSidebar();
    },
  },
  methods: {
    env(key) {
      return process.env[key];
    },
    async getCustomFeature() {
      const response = await customFeaturesApi.getCustomFeature(this.activeWorkspace._id).catch(() => {});
      if (response.error) {
        return;
      }
      if (response.data.chatbot_analytic) {
        this.menuList.push(menu('/chatbot-analytics', 'Bot Analytics', '', [], 'fa diagnoses', true));
      }
      if (response.data.ai_thread) {
        this.menuList.push(menu('/ai-threads', 'AI Threads', '', [], 'el-icon-receiving', true));
      }
    },
    mouseEnter() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.add('open-sidebar-folded');
      }
    },
    mouseLeave() {
      if (document.body.classList.contains('sidebar-folded')) {
        document.body.classList.remove('open-sidebar-folded');
      }
    },
    setSidebar() {
      if (cookie.get('sidebar-folded') === 'true' && this.isLargeScreen) {
        setTimeout(() => {
          this.toggleSidebar(false);
        }, 200);
      }
    },
    toggleSidebar(needToSetCookie = true) {
      document.body.classList.toggle(this.isLargeScreen ? 'sidebar-folded' : 'sidebar-open');
      this.$refs.sidebarToggler.classList.toggle('not-active');
      this.$refs.sidebarToggler.classList.toggle('active');
      this.isFolded = !this.isFolded;
      if (needToSetCookie && this.isLargeScreen) cookie.set('sidebar-folded', this.isFolded);
    },
    selectWorkspace() {
      this.$store.dispatch('workspace/clearActiveWorkspace')
        .then(() => {
          this.$router.push('/workspaces');
        });
    },
    async getLabelList() {
      await labelsAPI.getList(this.activeWorkspace._id)
        .then(async (res) => {
          this.labelList = res.data.rows.map((v) => {
            const item = v;
            item.to = `/conversations/labels/${item.id}`;
            item.icon = 'tag';
            return item;
          }).filter((v) => v.show_in_sidebar);
        })
        .catch(() => {});
    },
  },
  components: {
    SidebarCategory: () => import('./SidebarCategory.vue'),
    SidebarItem: () => import('./SidebarItem.vue'),
  },
};
</script>
